<template>
  <footer class="footer no-print">
    <div class="footer__image">
      <a href="https://policingequity.org" target="_blank">
        <img alt="CPE logo" src="../assets/cpe_logo_bw.png" />
      </a>
    </div>
    <div class="footer__text-container">
      <div class="footer__text">
        For questions or to submit feedback, contact
        <a href="mailto:justicenavigator@policingequity.org">justicenavigator@policingequity.org</a>
        .
      </div>
      <div class="footer__text">
        Sign up
        <a
          href="https://policingequity.org/sign-up?utm_source=web&utm_medium=page&utm_campaign=jn"
          target="_blank"
        >
          here
        </a>
        to receive updates about CPE's latest assessments, initiatives, and resources.
      </div>
    </div>
    <div class="footer__icons">
      <a
        href="https://www.facebook.com/CenterForPolicingEquity"
        target="_blank"
        aria-label="Navigate to CPE Facebook Page"
      >
        <font-awesome-icon :icon="['fab', 'facebook-square']" aria-hidden="true" class="fa-2x" />
      </a>
      <a
        href="https://twitter.com/PolicingEquity"
        target="_blank"
        aria-label="Navigate to CPE Twitter page"
      >
        <font-awesome-icon :icon="['fab', 'twitter-square']" aria-hidden="true" class="fa-2x" />
      </a>
      <a
        href="https://www.instagram.com/PolicingEquity/"
        target="_blank"
        aria-label="Navigate to CPE Instagram Page"
      >
        <font-awesome-icon :icon="['fab', 'instagram']" aria-hidden="true" class="fa-2x" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  margin-top: 2rem;
  font-family: $font-semi-bold;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: $color-deep-blue;
  padding: 2rem 1.5rem;
  color: $color-white;

  @include mq($jn-mobile) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }

  a,
  a:visited {
    color: $color-white;
    text-decoration: underline;
  }
}

// Margins between elements
.footer__image,
.footer__text {
  margin: 1rem 0.5rem;

  @include mq($jn-mobile) {
    margin-right: 2rem;
  }
}

// How elements should grow (only makes a difference for non-mobile)
.footer__image {
  flex-grow: 1;
}
.footer__text,
.footer__icon {
  flex-grow: 0;
}

.footer__icons {
  margin-top: 1rem;

  @include mq($jn-mobile) {
    margin-top: 0;
  }

  svg {
    margin: 0.5rem;
  }
}

img {
  max-height: 64px;
  height: auto;
}
</style>
