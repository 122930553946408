<template>
  <div id="app">
    <nav-bar></nav-bar>
    <router-view id="router-view" :key="$route.fullPath" role="main" />
    <page-footer></page-footer>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import NavBar from '@/components/NavBar.vue';
import PageFooter from '@/components/PageFooter.vue';
import { GDPR_COUNTRIES, IP_LOOKUP_API_KEY } from '@/constants';
import { bootstrap } from 'vue-gtag';

export default {
  name: 'App',
  components: {
    NavBar,
    PageFooter,
  },
  // check ip for gdpr country code before deciding whether to enable GTM
  beforeMount() {
    // Only check in production deployment
    if (window.CURRENT_ENV === 'production') {
      fetch('https://api.ipgeolocation.io/ipgeo?apiKey=' + IP_LOOKUP_API_KEY)
        .then((res) => res.json())
        .then((response) => {
          const countryCode = response.country_code2.toUpperCase();
          if (GDPR_COUNTRIES.indexOf(countryCode) === -1) {
            // enable gtm as user is not from a gdpr country
            this.$gtm.enable(true);
            bootstrap().then((gtag) => {});
          }
        })
        .catch((error) => {
          console.error(error);
          this.$gtm.enable(true);
          bootstrap().then((gtag) => {});
          Sentry.captureException(error);
        });
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/app';

#app {
  font-family: $font-base;
  @include font-size-medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
}
</style>
