<template>
  <nav
    class="nav-bar__container no-print"
    :class="navBarScrollClass"
    @mouseover="(event) => focusNavCheck(event)"
    @focus="(e) => focusNavCheck(e)"
  >
    <div class="nav-bar__top-nav">
      <router-link :to="{ name: homeLink }" class="nav-bar__logo" aria-label="link to homepage">
        <j-n-logo class="nav-bar__logo-desktop" alt="Justice Navigator logo" />
        <j-n-logo-mobile class="nav-bar__logo-mobile" alt="Justice Navigator logo" />
      </router-link>
      <div class="nav-bar__top-menu">
        <template v-for="(pageData, pageName) in topLevelPages">
          <!-- The `:class` attribute is an override so that the `router-link-active` class is added
          for the anchor link to `#home-assessments` when on the home page. Otherwise it won't necessarily
          get tagged as expected because the link is actually to `/report` which is a redirect. -->
          <router-link
            :key="pageName"
            :to="linkToPage(pageName)"
            :class="{ 'router-link-active': isLinkToHomeFromHome(pageName) }"
            class="nav-bar__top-menu-item"
          >
            {{ pageData.tabName }}
          </router-link>
          <top-menu-divider
            :key="pageData.tabName"
            alt="Menu item divider"
            class="nav-bar__top-menu-divider"
            aria-hidden="true"
          />
        </template>
      </div>
      <div v-if="isAuthenticated" class="nav-bar__top-nav-right">
        <span class="nav-bar__top-nav-email">{{ email }}</span>
        |
        <button class="nav-bar__top-nav-logoutLogin" @click="logoutUser">Logout</button>
      </div>
      <nav-menu-mobile
        :customDatasetPages="customDatasetPages"
        :currReport="currReport"
        :cmsContent="cmsContent"
        class="nav-bar__nav-menu-mobile"
      />
      <div v-if="!isAuthenticated" class="nav-bar__top-nav-right--private">
        <button v-if="isPrivate" class="nav-bar__top-nav-logoutLogin" @click="loginUser">
          Login
        </button>
      </div>
    </div>
    <h3 v-if="isReportSelected" class="nav-bar__report-title">
      {{ currReport.report_name }}
    </h3>
    <div class="nav-bar__tab">
      <div v-if="isReportSelected" class="nav-bar__tab-submenu">
        <template v-for="(page, i) of customDatasetPages">
          <page-link
            :key="page.pageName"
            :name="page.pageName"
            v-html="cmsContent[page.pageName][tab_name_id]"
            class="nav-bar__tab-submenu-item"
          />
          <div :key="page.pageName + i" class="nav-bar__tab-submenu-divider" aria-hidden="true">
            ●
          </div>
        </template>
      </div>
      <div v-if="showSubMenu" class="nav-bar__tab-submenu">
        <template v-for="(tab, i) in currTabArray">
          <router-link
            :key="tab.pageName"
            :to="{ name: tab.pageName }"
            class="nav-bar__tab-submenu-item"
          >
            {{ tab.tabName }}
          </router-link>
          <div :key="tab.pageName + i" class="nav-bar__tab-submenu-divider" aria-hidden="true">
            ●
          </div>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import NavMenuMobile from '@/components/NavMenuMobile.vue';
import PageLink from '@/components/PageLink.vue';
import JNLogo from '@/assets/jn-logo.svg';
import JNLogoMobile from '@/assets/jn-logo-mobile.svg';
import TopMenuDivider from '@/assets/top-menu-divider.svg';
import { DATASET_PAGES, TOP_NAV_PAGES } from '@/tab_constants';
import {
  SMALL_NAV_HEIGHT,
  TAG_COMP_TAB_TEXT,
  TAG_PAGE_ABOUT,
  TAG_PAGE_ABOUT_INDEX,
  TAG_PAGE_COMMUNITIES,
  TAG_PAGE_HOME,
  TAG_PAGE_HOME_PRIVATE,
  TAG_PAGE_LAW,
} from '@/constants';

export default {
  name: 'NavBar',
  components: {
    JNLogo,
    JNLogoMobile,
    NavMenuMobile,
    PageLink,
    TopMenuDivider,
  },
  data() {
    return {
      homePage: TAG_PAGE_HOME,
      privateHomePage: TAG_PAGE_HOME_PRIVATE,
      aboutPage: TAG_PAGE_ABOUT,
      lawPage: TAG_PAGE_LAW,
      communitiesPage: TAG_PAGE_COMMUNITIES,
      datasetPages: DATASET_PAGES,
      routesWithSubmenu: [TAG_PAGE_ABOUT_INDEX, TAG_PAGE_LAW, TAG_PAGE_COMMUNITIES],
      topLevelPages: TOP_NAV_PAGES,
      navBarScrollClass: {
        'nav-bar__collapse': false,
        'nav-bar__open': false,
      },
      scrollState: 0,
    };
  },
  computed: {
    cmsContent() {
      return this.$store.getters.pages || {};
    },
    tab_name_id() {
      return TAG_COMP_TAB_TEXT;
    },
    routeName() {
      return this.$route.name ? this.$route.matched[0].name : '';
    },
    isPrivate() {
      return this.$route.meta.isPrivate;
    },
    email() {
      return this.$store.state.user !== null ? this.$store.state.user.email : '';
    },
    isReportSelected() {
      return this.$store.getters.reportId;
    },
    showSubMenu() {
      return this.routesWithSubmenu.includes(this.routeName);
    },
    currReport() {
      return this.$store.state.currReport;
    },
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
    homeLink() {
      return this.isAuthenticated ? this.privateHomePage : this.homePage;
    },
    customDatasetPages() {
      let newPages = [...this.datasetPages];
      const cmsContent = this.$store.getters.pages || {};
      // filter out tabs where the tab name section does not exist in CMS meta post for this report i.e. we want to hide it
      newPages = newPages.filter(
        (page) =>
          cmsContent[page.pageName] && cmsContent[page.pageName][TAG_COMP_TAB_TEXT] !== undefined
      );

      if (this.currReport.report_status === 'public') {
        // filter out share tab from public reports. The share results meta page is identified by section id below
        newPages = newPages.filter(
          (page) => cmsContent[page.pageName]['share-tab-identifier'] === undefined
        );
      }
      return newPages;
    },
    currTabArray() {
      const pageData = this.topLevelPages[this.routeName];
      return pageData ? pageData.tabs : [];
    },
  },
  methods: {
    async loginUser() {
      await this.$auth.login();
    },
    async logoutUser() {
      await this.$auth.logout();
    },
    linkToPage(pageName) {
      // If this is the "Assessments" link, make it an anchor link to the assessment section
      if (pageName === 'page-report') return { name: pageName, hash: '#home-assessments' };
      return { name: pageName };
    },
    isLinkToHomeFromHome(pageName) {
      return (
        this.$route.hash && this.$route.hash === '#home-assessments' && pageName === 'page-report'
      );
    },
    scrollDetect(scrollDownEvent, scrollUpEvent) {
      // Amount of pixels the scroll position needs to change to trigger a
      // scroll event.  Allows fine tuning of scroll position without causing a scroll event
      const downScrollBuffer = 20;
      const upScrollBuffer = 50;

      // Current scroll position
      const currentScroll = this.scrollTop();
      if (currentScroll < SMALL_NAV_HEIGHT) {
        scrollUpEvent();
      } else {
        if (currentScroll > 0 && currentScroll > this.scrollState + downScrollBuffer) {
          scrollDownEvent();
        } else if (currentScroll + upScrollBuffer < this.scrollState) {
          scrollUpEvent();
        }
      }
      // Set previous scroll position
      this.scrollState = currentScroll;
    },
    // Returns current scroll position
    scrollTop() {
      return window.scrollY;
    },
    // Called when scrolled down
    collapseNav() {
      this.navBarScrollClass['nav-bar__collapse'] = true;
      this.navBarScrollClass['nav-bar__open'] = false;
    },
    // Called when scolled up
    openNav() {
      this.navBarScrollClass['nav-bar__collapse'] = false;
      this.navBarScrollClass['nav-bar__open'] = true;
    },
    // Opens nav if mousetip/focus is near the top of page
    focusNavCheck(event) {
      if (event.clientY < SMALL_NAV_HEIGHT) {
        this.openNav();
      }
    },
  },
  created() {
    window.addEventListener('scroll', () => {
      this.scrollDetect(this.collapseNav, this.openNav);
    });
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
      this.scrollDetect(this.collapseNav, this.openNav);
    });
  },
};
</script>

<style scoped lang="scss">
.nav-bar__container {
  @include font-size-small;
  box-sizing: border-box;
  background-color: $color-white;
  color: $color-deep-blue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;

  @include mq($jn-mobile) {
    border-bottom: 1px solid $color-teal;
  }
}

.nav-bar__top-nav {
  margin: 0 1rem;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-height: 64px;
  @include mq($jn-mobile) {
    margin: 0 2rem;
  }
}

.nav-bar__top-menu {
  display: none;
  @include mq($jn-mobile) {
    align-items: center;
    display: flex;
    justify-content: space-around;
    min-width: 350px;
    width: 50%;
  }
  @include mq($jn-tablet) {
    width: 35%;
  }
}

.nav-bar__top-menu-item {
  flex: none;
}

.nav-bar__top-menu-divider {
  height: 50%;
  min-width: 10px;
  &:last-of-type {
    display: none;
  }
}

.nav-bar__logo {
  display: flex;
  position: relative;
  height: 32px;
  min-width: 48px;
  margin-right: 1rem;
  @include mq($jn-tablet) {
    height: 36px;
    min-width: 200px;
    padding-top: 0.25rem;
  }
}

.nav-bar__logo-desktop {
  display: none;
  @include mq($jn-tablet) {
    display: flex;
    position: absolute;
  }
}

.nav-bar__logo-mobile {
  display: flex;
  position: absolute;
  @include mq($jn-tablet) {
    display: none;
  }
}

.nav-bar__tab-submenu {
  background-color: $color-pale-blue;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 64px;
}

.nav-bar__tab-submenu-divider {
  display: flex;
  margin: auto 0;
  &:last-child {
    display: none;
  }
}

.nav-bar__tab-submenu-item p {
  padding: 0;
  text-transform: lowercase;
}

.nav-bar__top-nav-right {
  display: none;
  @include mq($jn-mobile) {
    box-sizing: border-box;
    align-items: center;
    @include font-size-small;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    height: fit-content;
    padding: 0;
    margin: 0;
  }
}

.nav-bar__top-nav-right--private {
  display: none;
  @include mq($jn-mobile) {
    display: block;
    min-width: 20%;
    text-align: right;
  }
}

.nav-bar__top-nav-logoutLogin {
  color: $color-deep-blue;
  margin: 0;
  padding-right: 0;
  padding-left: 0.5rem;
  @include mq($jn-tablet) {
    padding-left: 1rem;
  }
}

.nav-bar__top-nav-logoutLogin:hover {
  color: $color-teal;
}

.nav-bar__top-nav-email {
  margin: 0.125rem 0.5rem;
  @include mq($jn-tablet) {
    margin: 0.125rem 1rem;
  }
}

.nav-bar__tab-submenu-item {
  color: $color-deep-blue;
  margin: 0;
  @include mq($jn-mobile) {
    margin: auto 1rem;
    padding: 0;
  }
  &:hover {
    color: $color-teal;
  }
}

.nav-bar__tab-submenu-item ::v-deep p {
  padding: 0;
  text-align: center;
}

a.router-link-active,
a.router-link-active:hover {
  color: $color-teal;
}

.nav-bar__report-title {
  display: none;
  @include mq($jn-mobile) {
    font-family: $font-semi-bold;
    @include font-size-mediumLarge;
    display: block;
    margin: 0;
    padding-bottom: 1.125rem;
    text-align: center;
  }
}

.nav-bar__nav-menu-mobile {
  display: flex;
  @include mq($jn-mobile) {
    display: none;
  }
}

.nav-bar__tab {
  display: none;
  @include mq($jn-mobile) {
    display: block;
  }
}

// Collapse nav bar on scroll down
.nav-bar__collapse {
  @include mq($jn-mobile) {
    animation: collapse 0.5s ease forwards;
  }
}

// Open nav bar on scroll up
.nav-bar__open {
  @include mq($jn-mobile) {
    animation: open 0.5s ease forwards;
  }
}

@keyframes collapse {
  0% {
    opacity: 1;
    top: 0;
  }
  90% {
    opacity: 0;
    top: -$sticky-nav-height-large;
  }
  100% {
    opacity: 0;
    top: calc(#{$sticky-nav-height-small} - #{$sticky-nav-height-large});
  }
}

@keyframes open {
  from {
    opacity: 0;
    top: -$sticky-nav-height-large;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
</style>
