<template>
  <router-link :to="{ name, params: { reportId } }" :target="target">{{ text }}</router-link>
</template>
<script>
export default {
  name: 'PageLink',
  props: {
    name: String,
    text: {
      type: String,
      default: '',
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    target() {
      return this.openInNewTab ? '_blank' : '';
    },
    reportId() {
      return this.$store.getters.reportId;
    },
  },
};
</script>
