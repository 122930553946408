import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
Vue.$cookies.config('7d');

// TODO make JSON file(s) for mock data
const STATIC_JSON_PATH = '../public/';

const dataEnv = process.env.VUE_APP_CHARLIE_FLASK_PATH || STATIC_JSON_PATH;

const ax = axios.create({
  baseURL: dataEnv,
});

// If basic auth cookie is set, send basic auth token with every API request.
// Note that we don't want to send this with any third-party requests, but this
// `api` construct is set up for only sending API requests to our own backend.
let basicAuthToken = Vue.$cookies.get('basic_auth');
if (basicAuthToken) {
  // Strip extra double quotes
  if (basicAuthToken[0] === '"' && basicAuthToken[basicAuthToken.length - 1] === '"') {
    basicAuthToken = basicAuthToken.substring(1, basicAuthToken.length - 1);
  }
  ax.defaults.headers.common.Authorization = basicAuthToken;
}

export default ax;

// TODO better response error handling, both globally and at a call-specific level
