import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api.js';
import router from '@/router';

Vue.use(Vuex);

const EMPTY_REPORT = {
  report_id: '',
  report_name: '',
  pages: null,
  charts: null,
  report_status: '',
  is_c4j: false,
};

export default new Vuex.Store({
  state: {
    user: null,
    idToken: '',
    // List of all reports the user has access to
    userReports: [],
    // @todo-refactor: this should be a dictionary keyed by report ID so we don't have to refetch
    // data when switching back to a report we've already viewed in that session. This will also
    // simplify the report fetching logic since we don't need to "clear" currReport.
    currReport: EMPTY_REPORT,
    h2jContent: null,
  },
  getters: {
    authenticated: (state) => {
      return state.user !== null && state.idToken !== '';
    },
    reportId: (state) => {
      return state.currReport.report_id;
    },
    reportStatus: (state) => {
      return state.currReport.report_status;
    },
    pages: (state) => {
      return state.currReport.pages;
    },
    charts: (state) => {
      return state.currReport.charts;
    },
    printedReport: (state) => {
      return state.currReport.printed_report;
    },
  },
  mutations: {
    setAuth(state, user, idToken) {
      state.user = user;
      state.idToken = idToken;
    },
    setReport(state, report) {
      state.currReport = report;
    },
    setReports(state, reportList) {
      state.userReports = reportList;
    },
    setH2JContent(state, h2jContent) {
      state.h2jContent = h2jContent;
    },
    resetState(state) {
      state.user = null;
      state.idToken = '';
      state.userReports = [];
      state.currReport = EMPTY_REPORT;
    },
  },
  actions: {
    logout({ commit }) {
      commit('resetState');
    },
    fetchH2JContent({ commit }) {
      api
        .get('h2j')
        .then((response) => {
          commit('setH2JContent', response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchReports({ commit, state }, force = false) {
      // Fetch all reports for the current user [{id, name}] from the backend
      // reset currReport if currReport isn't in the list returned from the backend.
      // Don't fetch reports if they are already present. We only need to fetch once.
      if (state.userReports.length > 0 && !force) return;
      try {
        const response = await api.get('reports');
        commit('setReports', response.data.reports);
        // @todo-cleanup: This shouldn't be in this action, but in the router method.
        //   I.e. this has nothing to do with fetching reports
        if (
          state.currReport &&
          !response.data.reports
            .map((report) => report.report_id)
            .includes(state.currReport.report_id)
        ) {
          commit('setReport', EMPTY_REPORT);
        }
      } catch (error) {
        console.error(error);
      }
    },
    fetchReport({ commit, state }, payload) {
      // If we already have data for this report, don't need to re-fetch it
      if (payload.report_id === state.currReport.report_id) return;
      // Fetch detailed report data about the specified report from the backend
      api
        .get('report', { params: payload })
        .then((response) => {
          // Only load the data if it is not stale.
          if (router.currentRoute.params.reportId === payload.report_id) {
            commit('setReport', response.data.report);
          }
        })
        .catch((error) => {
          const errorCode = error.response && error.response.status;
          const errorMessage =
            error.response && error.response.data && error.response.data.error_message;

          console.error(errorMessage);
          router.push({ name: 'error', params: { errorCode, errorMessage } });
        });
    },
    setEmptyReport({ commit }) {
      commit('setReport', EMPTY_REPORT);
    },
  },
});
