<template>
  <div class="nav-menu-mobile__container">
    <button class="nav-menu-mobile__toggle-button" @click="toggleMenu">
      <hamburger-menu v-if="!isMenuOpen" alt="Three parallel lines to show menu" />
      <close-menu v-else alt="Large X to hide menu" />
    </button>
    <div v-if="isMenuOpen" class="nav-menu-mobile__menu--open">
      <div
        v-if="isReportSelected && cmsContent"
        @click="toggleMenu"
        v-on:keyup.enter.space="toggleMenu"
      >
        <h1 class="nav-menu-mobile__current-report">
          {{ currReport.report_name }}
        </h1>
        <page-link
          v-for="page of customDatasetPages"
          :key="page.pageName"
          :name="page.pageName"
          v-html="cmsContent[page.pageName][tab_name_id]"
          class="nav-menu-mobile__assessment-links"
        ></page-link>
      </div>
      <div
        v-for="(pageData, pageName) in topLevelPages"
        :key="pageName"
        @click="toggleMenu"
        v-on:keyup.enter.space="toggleMenu"
        class="nav-menu-mobile__top-level"
      >
        <router-link :to="{ name: pageName }" class="nav-menu-mobile__top-level-link">
          {{ pageData.tabName }}
        </router-link>
        <div
          v-if="pageData.tabs && pageData.tabs.length && !isReportSelected"
          class="nav-menu-mobile__sub-link-group"
        >
          <router-link
            v-for="tab of pageData.tabs"
            :key="tab.pageName"
            :to="{ name: tab.pageName }"
            class="nav-menu-mobile__sub-link"
          >
            <span class="nav-menu-mobile__sub-link-text">{{ tab.tabName }}</span>
          </router-link>
        </div>
      </div>
      <button v-if="isAuthenticated" class="nav-menu-mobile__logout" @click="logoutUser">
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import PageLink from '@/components/PageLink.vue';
import HamburgerMenu from '@/assets/menu-burger.svg';
import CloseMenu from '@/assets/menu-x.svg';
import { TAG_COMP_TAB_TEXT } from '@/constants';
import { DATASET_PAGES, TOP_NAV_PAGES } from '@/tab_constants';

export default {
  name: 'NavMenuMobile',
  components: {
    PageLink,
    CloseMenu,
    HamburgerMenu,
  },
  data() {
    return {
      datasetPages: DATASET_PAGES,
      topLevelPages: TOP_NAV_PAGES,
      isMenuOpen: false,
    };
  },
  props: {
    customDatasetPages: {
      type: Array,
      default: () => [],
    },
    currReport: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cmsContent() {
      return this.$store.getters.pages;
    },
    isReportSelected() {
      return !!this.$store.getters.reportId;
    },
    tab_name_id() {
      return TAG_COMP_TAB_TEXT;
    },
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
  },
  methods: {
    async logoutUser() {
      await this.$auth.logout();
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        document.getElementById('app').classList.add('mobile-nav-visible');
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
      } else {
        document.getElementById('app').classList.remove('mobile-nav-visible');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-menu-mobile__container {
  background: $color-white;
  display: flex;
  flex-direction: column;
  height: 64px;
}

.nav-menu-mobile__toggle-button,
.nav-menu-mobile__toggle-button:hover {
  margin: auto;
  padding: 0;
}

.nav-menu-mobile__menu--open {
  background: $color-pale-blue;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  padding: 1.5rem;
  text-align: left;
}

.nav-menu-mobile__current-report {
  line-height: 34px;
  margin-bottom: 1.5rem;
}

.nav-menu-mobile__logout {
  color: $color-deep-blue;
  display: block;
  @include font-size-mediumLarge;
  margin: 3rem 0;
  padding: 0;

  &:hover,
  &:active {
    background: transparent;
    color: $color-teal;
  }
}

.nav-menu-mobile__top-level {
  margin-bottom: 1.5rem;
}

.nav-menu-mobile__top-level-link {
  @include font-size-mediumLarge;
}

.nav-menu-mobile__sub-link-group {
  padding-top: 1rem;
}

.nav-menu-mobile__assessment-links,
.nav-menu-mobile__sub-link {
  display: list-item;
  list-style-type: disc;
  margin: 0 0 1.5rem 2rem;
  &::marker {
    font-size: 16px;
  }
}

.nav-menu-mobile__assessment-links ::v-deep p,
.nav-menu-mobile__sub-link-text {
  @include font-size-mediumLarge;
  position: relative;
  left: 10px;
  padding: 0;
  margin: 0;
}

a.router-link-active,
a.router-link-active:hover,
a.router-link-active:active {
  color: $color-teal;
}
</style>
