import * as constants from '@/constants.js';

// tab names for dataset pages now pulled from cms
const DATASET_PAGES = [
  {
    pageName: constants.TAG_PAGE_SUMMARY,
  },
  {
    pageName: constants.TAG_PAGE_CONTEXT,
  },
  {
    pageName: constants.TAG_PAGE_UOF,
  },
  {
    pageName: constants.TAG_PAGE_VS,
  },
  {
    pageName: constants.TAG_PAGE_PS,
  },
  {
    pageName: constants.TAG_PAGE_CALL_DEMAND,
  },
  {
    pageName: constants.TAG_PAGE_DATA,
  },
  {
    pageName: constants.TAG_PAGE_C4J,
  },
  {
    pageName: constants.TAG_PAGE_SHARE,
  },
];

// TODO : pull tab names for H2J pages from CMS
const TOP_NAV_PAGES = {
  [constants.TAG_PAGE_ABOUT_INDEX]: {
    tabName: 'About',
    tabs: [
      {
        pageName: constants.TAG_PAGE_ABOUT,
        tabName: 'About the Justice Navigator',
      },
      {
        pageName: constants.TAG_PAGE_METHODOLOGY,
        tabName: 'Methodology',
      },
    ],
  },
  [constants.TAG_PAGE_REPORT]: {
    tabName: 'Assessments',
    pageName: constants.TAG_PAGE_REPORT,
  },
  [constants.TAG_PAGE_COMMUNITIES]: {
    tabName: 'Communities',
    tabs: [
      {
        pageName: constants.TAG_PAGE_TAKE_ACTION,
        tabName: 'Take Action',
      },
      {
        pageName: constants.TAG_PAGE_UNDERSTAND_DATA,
        tabName: 'Understand Your Results',
      },
      {
        pageName: constants.TAG_PAGE_UNDERSTAND_LOCAL_PD,
        tabName: 'Understand Your Department',
      },
      {
        pageName: constants.TAG_PAGE_IMPROVE_YOUR_DATA,
        tabName: 'Improve Your Data',
      },
    ],
  },
  [constants.TAG_PAGE_LAW]: {
    tabName: 'Law Enforcement',
    tabs: [
      {
        pageName: constants.TAG_PAGE_APPROACH,
        tabName: "CPE'S Approach",
      },
      {
        pageName: constants.TAG_PAGE_COLLECT,
        tabName: 'Collect Data',
      },
      {
        pageName: constants.TAG_PAGE_INVESTIGATE,
        tabName: 'Investigate Results',
      },
      {
        pageName: constants.TAG_PAGE_INTERVENTIONS,
        tabName: 'Implement Interventions',
      },
      {
        pageName: constants.TAG_PAGE_COMPSTAT,
        tabName: 'Compstat 4 Justice',
      },
      {
        pageName: constants.TAG_PAGE_RESULTS,
        tabName: 'Share Results',
      },
    ],
  },
};

export { DATASET_PAGES, TOP_NAV_PAGES };
