// Constant values for CMS dataset tags
export const TAG_PAGE_ADMIN = 'page-admin';
export const TAG_PAGE_ADMIN_ADD_LEA_USERS = 'page-admin-add-lea-users';
export const TAG_PAGE_ADMIN_DUPLICATE_POST = 'page-admin-duplicate-post';
export const TAG_PAGE_ADMIN_CMS_POSTS_VIEWER = 'page-admin-cms-posts-viewer';
export const TAG_PAGE_ADMIN_SHARED_COMPONENTS = 'page-admin-shared-components';
export const TAG_PAGE_ADMIN_FREEZE_REPORTS = 'page-admin-freeze-reports';
export const TAG_PAGE_SUMMARY = 'page-summary';
export const TAG_PAGE_SUMMARY_V2 = 'page-summary-v2';
export const TAG_PAGE_CONTEXT = 'page-context';
export const TAG_PAGE_VS = 'page-vs';
export const TAG_PAGE_PS = 'page-ps';
export const TAG_PAGE_UOF = 'page-uof';
export const TAG_PAGE_SHARE = 'page-share';
export const TAG_PAGE_DATA = 'page-datanotes';
export const TAG_PAGE_HOME = 'page-home';
export const TAG_PAGE_HOME_PRIVATE = 'page-home-private';
export const TAG_PAGE_REPORT = 'page-report';
export const TAG_PAGE_METHODOLOGY = 'page-methodologies';
export const TAG_PAGE_LAW = 'page-law';
export const TAG_PAGE_RESULTS = 'page-results';
export const TAG_PAGE_COMMUNITIES = 'page-communities';
export const TAG_PAGE_COMMUNITIES_INVESTIGATE = 'page-communities-investigate';
export const TAG_PAGE_COMMUNITIES_INTERVENTIONS = 'page-communities-interventions';
export const TAG_PAGE_APPROACH = 'page-approach';
export const TAG_PAGE_COLLECT = 'page-collect';
export const TAG_PAGE_INVESTIGATE = 'page-investigate';
export const TAG_PAGE_INTERVENTIONS = 'page-interventions';
export const TAG_PAGE_COMPSTAT = 'page-compstat';
export const TAG_PAGE_OFFICERS = 'page-officers';
export const TAG_PAGE_MEDIA = 'page-media';
export const TAG_PAGE_ENGAGE_COMMUNITIES = 'page-engage-communities';
export const TAG_PAGE_POLICYMAKERS = 'page-policymakers';
export const TAG_PAGE_ERROR_400 = 'page-error-400';
export const TAG_PAGE_ERROR_500 = 'page-error-500';
export const TAG_PAGE_UNDERSTAND_DATA = 'page-understand-data';
export const TAG_PAGE_GLOSSARY = 'page-glossary';
export const TAG_PAGE_NO_ASSESSMENT = 'page-no-assessment';
export const TAG_PAGE_UNDERSTAND_LOCAL_PD = 'page-understand-local-pd';
export const TAG_PAGE_UNDERSTAND_ANALYSIS = 'page-understand-analysis';
export const TAG_PAGE_TAKE_ACTION = 'page-take-action';
export const TAG_PAGE_IMPROVE_YOUR_DATA = 'page-improve-your-data';
export const TAG_PAGE_ABOUT = 'page-about';
export const TAG_PAGE_ABOUT_INDEX = 'page-about-index';
export const TAG_PAGE_CALL_DEMAND = 'page-call-demand';
export const TAG_PAGE_C4J = 'page-c4j';

export const TAG_COMP_TITLE = 'title';
export const TAG_COMP_TITLE_TEXT = 'title_text';
export const TAG_COMP_TAB_TEXT = 'tab_text';
export const TAG_COMP_BODY_TEXT = 'body_text';
export const TAG_COMP_HOME_CONTACT = 'homeContact';
export const TAG_COMP_CARDS = 'cards';
export const TAG_COMP_CHARTS = 'charts';

export const STAGING_GHOST_URL_PREFIX = 'https://staging.justicenavigator.org/ghost/#/editor/post/';

export const GDPR_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland //Northern Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal, Azores, Madeira
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'JE', // Channel Isles
  'GB', // England, United Kingdom, Scotland, Wales
  'IS', // Iceland
  'LI', // Lichtenstein
  'NO', // Norway
  'IC', // Canary Islands
  'GP', // Guadeloupe
  'GF', // French Guiana
  'MQ', // Martinique
  'YT', // Mayotte
  'RE', // Reunion
  'MF', // Saint Martin
];

// API key for https://ipgeolocation.io/ip-location/ used to check country of ip origin for user
export const IP_LOOKUP_API_KEY = '6aed4b408b144d48a7ecba9d2b932a69';

export const INTERNAL_USER_EMAIL_DOMAINS = ['policingequity.org', 'google.com'];

export const LARGE_NAV_HEIGHT = 164;
export const SMALL_NAV_HEIGHT = 64;
export const MOBILE_BREAKPOINT_MAX = 767;
