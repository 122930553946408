import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import HighchartsVue from 'highcharts-vue';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCircle,
  faExternalLinkAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// tooltip
import FloatingVue from 'floating-vue';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import 'floating-vue/dist/style.css';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import accessibility from 'highcharts/modules/accessibility';

// Mapping library
import { LMap, LTileLayer, LGeoJson, LControl, LControlAttribution } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet/dist/leaflet-src.esm';

window.L = L;

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-control', LControl);
Vue.component('l-control-attribution', LControlAttribution);

exportingInit(Highcharts);
accessibility(Highcharts);

window.CURRENT_ENV =
  window.location.host === 'beta.justicenavigator.org'
    ? 'beta'
    : window.location.host === 'staging.justicenavigator.org'
    ? 'staging'
    : process.env.NODE_ENV;

Sentry.init({
  Vue,
  dsn: 'https://79f1b4e051144f478938bf648b0b1bf9@o703882.ingest.sentry.io/5781120',
  environment: window.CURRENT_ENV,
  logErrors: process.env.NODE_ENV === 'development', // Show errors in the dev console in development
});

library.add(
  faBars,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCircle,
  faCircleRegular,
  faExternalLinkAlt,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTimes,
  faTwitterSquare
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

Vue.use(HighchartsVue);

const gtagId = process.env.VUE_APP_GTAG_ID;

Vue.use(
  VueGtag,
  {
    config: {
      id: gtagId,
    },
    bootstrap: false,
  },
  router
);

Vue.use(FloatingVue, {
  themes: {
    'chart-tooltip': {
      delay: { show: 0, hide: 0 },
      distance: -25,
      skidding: 25,
      placement: 'top',
      triggers: ['focus', 'hover'],
    },
  },
});

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = function (event) {
      // check that the click was outside the el and its children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
