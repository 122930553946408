import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
import * as constants from '@/constants.js';
import store from '@/store'; // eslint-disable-line
import { Auth0Plugin } from '@/auth';

Vue.use(VueRouter);

Vue.use(Auth0Plugin, {
  domain: 'auth.policingequity.org',
  clientId: 'Uy685IqOIzIt7lIu9ahWTf1x4wI3csxl',
});

const routes = [
  {
    path: '/',
    name: constants.TAG_PAGE_HOME,
    component: () => import('../views/Home.vue'),
    meta: { isPrivate: false },
  },
  {
    path: '/private',
    name: constants.TAG_PAGE_HOME_PRIVATE,
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: true, isPrivate: true },
  },
  {
    path: '/admin',
    name: constants.TAG_PAGE_ADMIN,
    component: () => import('../views/admin/Admin.vue'),
    meta: { requiresAuth: true, isPrivate: true },
    // redirect: { name: constants.TAG_PAGE_ADMIN_ADD_LEA_USERS },
    children: [
      {
        path: 'add-lea-users',
        name: constants.TAG_PAGE_ADMIN_ADD_LEA_USERS,
        component: () => import('../views/admin/AddLeaUsers.vue'),
        meta: { requiresAuth: true, isPrivate: true },
      },
      {
        path: 'duplicate-post',
        name: constants.TAG_PAGE_ADMIN_DUPLICATE_POST,
        component: () => import('../views/admin/DuplicatePost.vue'),
        meta: { requiresAuth: true, isPrivate: true },
      },
      {
        path: 'cms-posts',
        name: constants.TAG_PAGE_ADMIN_CMS_POSTS_VIEWER,
        component: () => import('../views/admin/CmsPostsViewer/index.vue'),
        meta: { requiresAuth: true, isPrivate: true },
      },
      {
        path: 'shared-components',
        name: constants.TAG_PAGE_ADMIN_SHARED_COMPONENTS,
        component: () => import('../views/admin/SharedComponents.vue'),
        meta: { requiresAuth: true, isPrivate: true },
      },
      {
        path: 'freeze-reports',
        name: constants.TAG_PAGE_ADMIN_FREEZE_REPORTS,
        component: () => import('../views/admin/FreezeReports.vue'),
        meta: { requiresAuth: true, isPrivate: true },
      },
    ],
  },
  // This is commented out since the data acquisition portal is currently deprecated.
  // {
  //   path: '/portal',
  //   name: 'portal',
  //   component: () => import('../views/UploadPortal.vue'),
  //   meta: { requiresAuth: true, hideNavContent: true },
  // },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/ErrorPage.vue'),
    props: true,
    meta: { hideNavContent: true },
  },
  {
    path: '/about-justice-navigator',
    redirect: {
      name: constants.TAG_PAGE_ABOUT,
    },
  },
  {
    path: '/about',
    name: constants.TAG_PAGE_ABOUT_INDEX,
    component: {
      render: (c) => c('router-view'),
    },
    redirect: { name: constants.TAG_PAGE_ABOUT },
    children: [
      {
        path: 'justice-navigator',
        name: constants.TAG_PAGE_ABOUT,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'methodology',
        name: constants.TAG_PAGE_METHODOLOGY,
        component: () => import('../views/H2JPage.vue'),
      },
    ],
  },
  {
    path: '/for-law-enforcement',
    name: constants.TAG_PAGE_LAW,
    component: {
      render: (c) => c('router-view'),
    },
    redirect: { name: constants.TAG_PAGE_APPROACH },
    children: [
      {
        path: 'cpe-approach',
        name: constants.TAG_PAGE_APPROACH,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'collect-data',
        name: constants.TAG_PAGE_COLLECT,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'investigate',
        name: constants.TAG_PAGE_INVESTIGATE,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'implement-interventions',
        name: constants.TAG_PAGE_INTERVENTIONS,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'compstat-4-justice',
        name: constants.TAG_PAGE_COMPSTAT,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'share-results',
        name: constants.TAG_PAGE_RESULTS,
        component: () => import('../views/H2JPage.vue'),
      },
    ],
  },
  {
    path: '/for-communities',
    name: constants.TAG_PAGE_COMMUNITIES,
    component: {
      render: (c) => c('router-view'),
    },
    redirect: { name: constants.TAG_PAGE_TAKE_ACTION },
    children: [
      {
        path: 'take-action',
        name: constants.TAG_PAGE_TAKE_ACTION,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'understand-local-pd',
        name: constants.TAG_PAGE_UNDERSTAND_LOCAL_PD,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'take-action/understand-analysis',
        name: constants.TAG_PAGE_UNDERSTAND_ANALYSIS,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'take-action/implement-interventions',
        name: constants.TAG_PAGE_COMMUNITIES_INTERVENTIONS,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'understand-data',
        name: constants.TAG_PAGE_UNDERSTAND_DATA,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'understand-data/glossary',
        name: constants.TAG_PAGE_GLOSSARY,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'understand-data/investigate',
        name: constants.TAG_PAGE_COMMUNITIES_INVESTIGATE,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'understand-data/no-assessment',
        redirect: 'improve-your-data/no-assessment',
      },
      {
        path: 'improve-your-data',
        name: constants.TAG_PAGE_IMPROVE_YOUR_DATA,
        component: () => import('../views/H2JPage.vue'),
      },
      {
        path: 'improve-your-data/no-assessment',
        name: constants.TAG_PAGE_NO_ASSESSMENT,
        component: () => import('../views/H2JPage.vue'),
      },
    ],
  },
  {
    path: '/report',
    name: constants.TAG_PAGE_REPORT,
    component: {
      render: (c) => c('router-view'),
    },
    redirect: { name: constants.TAG_PAGE_HOME },
    children: [
      {
        path: ':reportId',
        redirect: { name: constants.TAG_PAGE_SUMMARY },
      },
      {
        path: ':reportId/summary',
        name: constants.TAG_PAGE_SUMMARY,
        component: () => import('../views/Summary.vue'),
      },
      {
        path: ':reportId/context',
        name: constants.TAG_PAGE_CONTEXT,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/ps',
        name: constants.TAG_PAGE_PS,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/vs',
        name: constants.TAG_PAGE_VS,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/uof',
        name: constants.TAG_PAGE_UOF,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/data-notes',
        name: constants.TAG_PAGE_DATA,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/share-results',
        name: constants.TAG_PAGE_SHARE,
        component: () => import('../views/SharingPage.vue'),
      },
      {
        path: ':reportId/call-demand',
        name: constants.TAG_PAGE_CALL_DEMAND,
        component: () => import('../views/DataPage.vue'),
      },
      {
        path: ':reportId/what-is-c4j',
        name: constants.TAG_PAGE_C4J,
        component: () => import('../views/DataPage.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../views/ErrorPage.vue'),
    props: { errorCode: 400 },
    meta: { hideNavContent: true },
  },
];

const scrollBehavior = function (to, from, savedPosition) {
  if (to.hash) {
    return { selector: to.hash };
  }
  if (!to.params.cardId) {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior,
  linkActiveClass: 'router-link-active',
});

// Auth guard - automatically redirect users to sign-in page for "requiresAuth" routes or to access
// non-public reports.
router.beforeEach(async (to, from, next) => {
  const authed = await router.app.$auth.authenticated();
  await store.dispatch('fetchReports');
  if (to.meta.requiresAuth && !authed) {
    router.app.$auth.login();
  } else if (to.path.startsWith('/report/') && !authed) {
    // Check whether the report that the user is trying to view is not public and redirect to
    // login if necessary.
    const matchingReport = store.state.userReports.find(
      (report) => report.report_id === to.params.reportId
    );
    if (!matchingReport || matchingReport.report_status !== 'public') {
      router.app.$auth.login();
    } else {
      next();
    }
  } else {
    next();
  }
});

// Auth callback handler has the "code" and "state" params.
router.beforeEach(async (to, from, next) => {
  // Auth0 redirects back to / with two sets of parameters:
  // If the auth flow was successful, code and state are set as query params.
  // If the auth flow failed, error and state are set.
  // Either way, handleRedirectCallback should be called.
  if ((to.query.code && to.query.state) || (to.query.error && to.query.state)) {
    try {
      await router.app.$auth.handleRedirectCallback();
    } catch (e) {
      alert(e);
      router.app.$auth.login();
    }
    delete to.query.code;
    delete to.query.state;
    const path = to.query.path || '/';
    delete to.query.path;
    next(path);
    return;
  }
  next();
});

router.beforeResolve(async (to, from, next) => {
  const payload = { report_id: to.params.reportId };
  if (to.query.version_id) payload.version_id = to.query.version_id;
  if (to.params.reportId) {
    store.dispatch('fetchReport', payload);
  } else {
    store.dispatch('setEmptyReport');
  }
  next();
});

const gtmId = process.env.VUE_APP_GTM_ID;

Vue.use(VueGtm, {
  id: gtmId,
  defer: false,
  enabled: false,
  debug: process.env.NODE_ENV !== 'production',
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

export default router;
