import Vue from 'vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import api from '@/api';
import store from '@/store';
import { INTERNAL_USER_EMAIL_DOMAINS } from '@/constants';

const plugin = ({ ...options }) => {
  return new Vue({
    data() {
      return {
        auth0Client: null,
      };
    },
    methods: {
      async login() {
        const client = await this.auth0Client;
        try {
          await client.loginWithRedirect({
            redirect_uri:
              window.location.origin +
              '/?path=' +
              encodeURIComponent(`${window.location.pathname}${window.location.search}`),
            prompt: 'login',
          });
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
      async logout() {
        Vue.$cookies.remove('auth0_id_token');
        const client = await this.auth0Client;
        return client.logout({
          // Redirect to home.
          returnTo: window.location.origin + '/private',
        });
      },
      async handleRedirectCallback() {
        const client = await this.auth0Client;
        // TODO: handle auth flow errors.
        await client.handleRedirectCallback();
        await this.updateState();
      },
      async updateState() {
        const client = await this.auth0Client;
        const authed = await this.authenticated();
        if (authed) {
          const user = await client.getUser();
          const idToken = (await client.getIdTokenClaims()).__raw;
          // Note that this means we'll send the auth0 token with every axios request.
          // It's important that we don't make 3rd party requests with this token.
          api.defaults.headers.common['X-JWT'] = `Bearer ${idToken}`;
          store.commit('setAuth', user, idToken);

          // push authenticated user info to dataLayer for gtm
          const isInternalUser =
            user.email && INTERNAL_USER_EMAIL_DOMAINS.includes(user.email.split('@')[1]);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            isInternalUser,
            userId: user.sub,
          });
        } else {
          store.dispatch('logout');
          if (
            api.defaults.headers.common['X-JWT'] &&
            api.defaults.headers.common['X-JWT'].startsWith('Bearer')
          ) {
            api.defaults.headers.common['X-JWT'] = '';
          }
        }
        await store.dispatch('fetchReports', true);
        store.dispatch('fetchH2JContent');
      },
      async authenticated() {
        const client = await this.auth0Client;
        try {
          return await client.isAuthenticated();
        } catch (e) {
          alert(e);
          this.login();
        }
      },
    },
    async created() {
      this.auth0Client = new Auth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        cacheLocation: 'localstorage',
      });
      await this.updateState();
    },
  });
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = plugin(options);
  },
};
